<template>
  <div>
    <b-modal
      id="termsAgreementModal"
      :ok-disabled="!termsAgreed || termsLoading"
      @ok="agree('terms', $event)"
      @hidden="reset('terms')"
      @shown="reset('terms')"
      no-close-on-backdrop
      ok-variant="success"
      ok-title="I Agree"
      no-close-on-esc
      no-stacking
      hide-header
      ok-only
    >
      <b-overlay :show="termsLoading">
        <b-row align-v="center">
          <b-col cols="12">
            <b-form-checkbox v-model="termsAgreed">
              By checking this box you agree that you have read and understand
              the <a href="https://streamlinedbusinesssolutions.com/terms-of-service/" style="color:#007bff;">terms of service</a>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>

    <b-modal
      id="userAgreementModal"
      :ok-disabled="!userAgreed || userLoading"
      @ok="agree('user', $event)"
      @hidden="reset('user')"
      @shown="reset('user')"
      no-close-on-backdrop
      ok-variant="success"
      ok-title="I Agree"
      no-close-on-esc
      no-stacking
      hide-header
      ok-only
    >
      <b-overlay :show="userLoading">
        <b-row align-v="center">
          <b-col cols="12">
            <b-form-checkbox v-model="userAgreed">
              By checking this box you agree and understand that the dashboard is for informational purposes only to help
              understand financial information, and in no way substitutes the accurate financial reports presented by SBS
              at year end for Tax Preparation. Third party access may be used for the purpose of obtaining necessary data
              to meet agreed upon terms.
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>

    <b-modal
      id="dashAgreementModal"
      :ok-disabled="dashLoading"
      size="lg"
      width="95%"
      max-width="500px"
      @ok="agree('dash', $event)"
      @hidden="reset('dash')"
      @shown="reset('dash')"
      no-close-on-backdrop
      ok-variant="success"
      ok-title="I Agree"
      hide-header-close
      no-close-on-esc
      no-stacking
      ok-only
      header-class="justify-content-center"
      body-class="justify-content-center"
      footer-class="justify-content-center"
    >
      <div slot="modal-title" style="margin:0 auto;">
        <center><img :src="'/img/logo/black_red.png'" style="height: 35px; width: 74%;"></center>
        <h5 style="justify-content: center; text-align: center;">Dashboard Terms and Conditions</h5>
      </div>
      <b-overlay :show="dashLoading">
        <b-row align-v="center">
          <b-col cols="12">
            <p>
              Our company is pleased to provide clients with the opportunity to utilize their DIRECT financial data for benchmarking analysis.
              By leveraging this valuable information, businesses can gain valuable insights into industry performance, financial benchmarks,
              and competitive trends. By accepting these terms, the client authorizes the company to use their DIRECT financial data in an anonymous and confidential manner,
              in accordance with applicable privacy laws and regulations. This anonymized approach ensures privacy while enabling accurate and objective analysis.
              Leveraging our clients' DIRECT financial data for benchmarking analysis paves the way for informed decision-making, strategic growth,
              and improved performance within the confines of legal compliance.
            </p>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/UserService'

export default {
  data () {
    return {
      termsLoading: false,
      termsAgreed: false,
      userLoading: false,
      userAgreed: false,
      dashLoading: false
    }
  },

  created () {
    this.checkAgreements()
  },

  methods: {
    checkAgreements () {
      UserService.getUserInfo().then(
        usr => {
          if (usr && usr.apitoken) {
            UserService.checkAgreements().then(
              (response) => {
                if (response.data.info) {
                  if (response.data.info.terms) {
                    this.$bvModal.show('termsAgreementModal')
                  }
                  if (response.data.info.user) {
                    this.$bvModal.show('userAgreementModal')
                  }
                  if (response.data.info.dash) {
                    this.$bvModal.show('dashAgreementModal')
                  }
                }
              }
            )
          }
        }
      )
    },

    agree (type, bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this[type + 'Agreed'] && !this[type + 'Loading']) {
        this[type + 'Loading'] = true
        UserService.setAgreed(type).then(
          (response) => {
            this.$nextTick(() => {
              this.$bvModal.hide(type + 'AgreementModal')
            })
          },
          (err) => {
            this.$aimNotify.notify(err.response)
          }
        ).finally(() => { this[type + 'Loading'] = false })
      // below is only for new tac agreement
      } else if (type === 'dash') {
        this.dashLoading = true
        UserService.setAgreed(type).then(
          (response) => {
            this.$nextTick(() => {
              this.$bvModal.hide('dashAgreementModal')
            })
          },
          (err) => {
            this.$aimNotify.notify(err.response)
          }
        ).finally(() => { this.dashLoading = false })
      }
    },

    reset (type) {
      this[type + 'Agreed'] = false
    }
  },

  watch: {
    '$route' (to, from) {
      this.checkAgreements()
    }
  }
}
</script>
