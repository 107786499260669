<template>
  <div>
    <b-modal
      id="pwResetModal"
      :ok-disabled="loading || $v.$invalid"
      @ok="submit"
      @hidden="reset()"
      @shown="reset()"
      no-close-on-backdrop
      ok-variant="success"
      ok-title="Submit"
      no-close-on-esc
      no-stacking
      hide-header
      ok-only
    >
      <h3>Your password needs to be reset</h3><br>
      <b-overlay :show="loading">
        <b-form @submit.prevent="submit(null)">
          <b-form-group label="New Password">
            <b-form-input
              :state="!$v.user.password.$error ? null : false"
              placeholder="New Password"
              v-model="$v.user.password.$model"
              @blur="$v.user.password.$touch()"
              type="password"
              require
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.user.password.required">
              Password is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.user.password.minLength">
              Password must be at least 6 characters long
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Repeat Password">
            <b-form-input
              :state="!$v.user.password_confirm.$error ? null : false"
              placeholder="Repeat Password"
              v-model="$v.user.password_confirm.$model"
              @blur="$v.user.password_confirm.$touch()"
              type="password"
              require
            ></b-form-input>
            <b-form-invalid-feedback v-if="!$v.user.password_confirm.required">
              Password is required
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.user.password_confirm.sameAsPassword">
              Passwords must match
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'
import { bus } from '@/main'

export default {
  data () {
    return {
      user: {
        password: null,
        password_confirm: null
      },
      loading: false
    }
  },

  mounted () {
    this.checkReset()
    bus.$on('login', () => {
      this.checkReset()
    })
  },

  mixins: [validationMixin],
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(6)
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },

  methods: {
    checkReset () {
      UserService.getUserInfo().then(
        usr => {
          if (usr && usr.apitoken) {
            UserService.checkPasswordReset().then(
              (res) => {
                if (res.data.info.reset > 0) {
                  this.$bvModal.show('pwResetModal')
                }
              }
            )
          }
        }
      )
    },

    submit (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }
      this.loading = true

      UserService.saveNewPassword(this.user).then(
        (response) => {
          if (response.data) {
            this.$aimNotify.notify(response)
            this.$nextTick(() => {
              this.$bvModal.hide('pwResetModal')
            })
          }
        }
      ).catch(
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loading = false })
    },

    reset () {
      this.user = {
        password: null,
        password_confirm: null
      }
    }
  },

  watch: {
    '$route' (to, from) {
      this.checkReset()
    }
  }
}
</script>
