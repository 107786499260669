import Vue from 'vue'
import VueRouter from 'vue-router'
import UserService from '@/services/UserService'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'SBS Home',
      anonymous: true // allow unauthenticated users
    }
  },

  {
    path: '/contract/:hash',
    name: 'Contract',
    component: () => import('@/views/Contract.vue'),
    props: true,
    meta: {
      title: 'Contract',
      anonymous: true
    }
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      title: 'About SBS'
    }
  },

  {
    path: '/dashboard',
    name: 'Client Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      title: 'Dashboard'
    }
  },

  {
    path: '/help',
    name: 'Client Help',
    component: () => import('@/views/Help.vue'),
    meta: {
      title: 'Help'
    }
  },

  {
    path: '/admin/help',
    name: 'Admin Help',
    component: () => import('@/views/HelpAdmin.vue'),
    meta: {
      title: 'Help Admin',
      admin: true
    }
  },

  {
    path: '/admin/employee',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Employee Admin',
        meta: { admin: true },
        component: () => import('@/views/admin/Employee.vue')
      },
      {
        path: 'activity',
        name: 'Activity Report',
        meta: { title: 'Employee Activity', admin: true },
        component: () => import('@/views/admin/EmployeeActivity.vue')
      },
      {
        path: ':id',
        name: 'Employee edit',
        props: true,
        component: () => import('@/views/admin/EmployeeAdmin.vue'),
        meta: { admin: true }
      }
    ]
  },

  {
    path: '/admin/users',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        path: ':id?',
        name: 'User Admin',
        props: true,
        meta: { admin: true, title: 'User Admin' },
        component: () => import('@/views/admin/UserAdmin.vue')
      }
    ]
  },

  {
    path: '/admin/company',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Company Admin',
        meta: { title: 'Company Admin', admin: true },
        component: () => import('@/views/admin/CompanyList.vue')
      },
      // This was organization admin page that we don't use anymore
      // !!!! may go back and delete !!!!
      // {
      //   path: ':id',
      //   name: 'Organization edit',
      //   props: true,
      //   component: () => import('@/views/admin/OrganizationAdmin.vue'),
      //   meta: { title: 'Organization Edit', admin: true }
      // },
      {
        path: ':id',
        name: 'Company Edit',
        props: true,
        component: () => import('@/views/admin/CompanyAdmin.vue'),
        meta: { title: 'Company Edit', admin: true }
      },
      {
        path: ':orgId/user/:userId',
        name: 'User edit',
        props: true,
        component: () => import('@/views/admin/OrgUserAdmin.vue'),
        meta: { title: 'User Edit', admin: true }
      },
      {
        path: 'plaid',
        name: 'Plaid Configuration',
        meta: { title: 'Plaid Admin' },
        component: () => import('@/views/admin/company/Plaid.vue')
      }
    ]
  },

  {
    path: '/admin/industry',
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        path: '',
        name: 'Industry Average',
        meta: { title: 'Industry Average', admin: true },
        component: () => import('@/views/admin/industry/List.vue')
      },
      {
        path: 'edit/:id?',
        name: 'Industry Average Edit',
        meta: { title: 'Industry Average Edit', admin: true },
        props: true,
        component: () => import('@/views/admin/industry/Edit.vue')
      },
      {
        path: 'report',
        name: 'Industry Average Report',
        meta: { title: 'Industry Average Report', admin: true },
        props: true,
        component: () => import('@/views/admin/industry/Report.vue')
      }
    ]
  },

  {
    path: '/admin/onboarding',
    name: 'Onboarding',
    component: () => import('@/views/admin/Onboarding.vue'),
    meta: {
      title: 'On Boarding',
      admin: true
    }
  },

  {
    path: '/admin/templates',
    name: 'Templates Admin',
    component: () => import('@/views/admin/Templates.vue'),
    meta: {
      title: 'Templates Admin',
      admin: true
    }
  },

  {
    path: '/account',
    name: 'Account Page',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/ClientSettings.vue'),
    meta: {
      title: 'Account'
    }
  },

  {
    path: '/contact',
    name: 'Contact Page',
    component: () => import('@/views/Contact.vue'),
    meta: {
      title: 'Contact Page'
    }
  },

  {
    path: '/messaging',
    name: 'Messaging',
    component: () => import('@/views/Messaging.vue'),
    meta: {
      title: 'Messaging'
    }
  },

  {
    path: '/feedback',
    name: 'Transaction Feedback',
    component: () => import('@/views/Feedback.vue'),
    meta: {
      title: 'Transaction Feedback'
    }
  },

  {
    path: '/bookkeeping',
    component: () => import('@/views/Bookkeeping.vue'),
    children: [
      {
        path: '',
        name: 'BookkeeperQueue',
        meta: { title: 'Bookkeeper Queue' },
        component: () => import('@/views/bookkeeping/Queue.vue')
      },
      {
        path: 'code/:id?/:date?/:selectedtab?',
        props: true,
        name: 'BookkeeperCoding',
        meta: { title: 'Bookkeeper Coding' },
        component: () => import('@/views/bookkeeping/Coding.vue')
      }
    ]
  },

  {
    path: '/documents/:compId?',
    name: 'Documents',
    props: true,
    component: () => import('@/views/Document.vue'),
    meta: { title: 'Documents' }
  },

  {
    path: '/plaid',
    name: 'Plaid',
    component: () => import('@/views/Plaid.vue'),
    meta: { title: 'Plaid' }
  },

  {
    path: '/summary',
    name: 'Summary',
    component: () => import('@/views/Summary.vue'),
    meta: { title: 'Profit and Loss Summary' }
  },

  {
    path: '/report',
    component: () => import('@/views/Reports.vue'),
    children: [
      {
        path: '',
        name: 'reports',
        meta: { title: 'Reports List' },
        component: () => import('@/views/reports/List.vue')
      },
      {
        path: 'ticket',
        name: 'ticketreport',
        meta: { title: 'Ticket Report' },
        component: () => import('@/views/reports/Ticket.vue')
      },
      {
        path: 'company',
        name: 'companyreport',
        meta: { title: 'Company Report' },
        component: () => import('@/views/reports/Company.vue')
      },
      {
        path: 'quickbooks',
        name: 'quickbooksreport',
        meta: { title: 'Quickbooks Report' },
        component: () => import('@/views/reports/Quickbooks.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const loggedIn = UserService.isLoggedIn()
  if (!to.meta.anonymous && !loggedIn) {
    return next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }

  const isAdmin = UserService.isAdmin()
  const isBookkeeper = UserService.isBookkeeper()
  if (to.meta.admin && !isAdmin) {
    console.log('not admin redirecting')
    return next({
      path: '/'
    })
  }

  if (loggedIn) {
    if (isAdmin) {
      to.meta.layout = 'admin'
    } else if (isBookkeeper) {
      // hide other links for now
      to.meta.layout = 'bookkeeper'
    } else {
      to.meta.layout = 'default'
    }
  } else {
    to.meta.layout = 'anonymous'
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
