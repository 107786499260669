import axios from 'axios'
import { bus } from '@/main'
import router from '@/router' // vue router won't work through Vue.prototype
import Vue from 'vue'
import StorageService from '@/services/StorageService'

const UserService = {

  // Returns user info object
  async getUserInfo () {
    return await this.verify('user')
  },

  async getRefreshedUser () {
    await localStorage.removeItem('user_updated')
    return await this.verify('user')
  },

  async login (username, password, redirect, mask = 0) {
    const data = {
      username: username,
      password: password,
      redirect: redirect || null,
      locale: window.navigator.language
    }

    if (mask && mask > 0) {
      data.masquerade = {
        user_id: mask
      }
    }

    return axios({
      url: axios.defaults.baseURL + 'login',
      method: 'POST',
      data: JSON.stringify(data)
    }).then(async (response) => {
      if (response && response.data && response.data.result) {
        StorageService.set('user', response.data.info)
        this.setHeaders()
        bus.$emit('login')
        bus.$emit('user', new Date())
      }
      return response
    })
  },

  async logout () {
    delete axios.defaults.headers.common.Authorization
    StorageService.remove('user')
    StorageService.remove('messages')
    Vue.prototype.$aimNotify.notify(null, 'info', 'Logged Out', 'You are now logged out.')
    bus.$emit('logout')
    router.push('/').catch(err => {
      console.log(err)
      window.location.href = '/' // hard reload if push refuses
    })
  },

  async verify (key) {
    this.setHeaders()
    const info = StorageService.get(key)
    if (info && !StorageService.isExpired(key)) {
      return info
    } else {
      const response = await this.refresh()
      if (response !== null) {
        StorageService.set('user', response.data.info)
        bus.$emit('user', new Date())
        return response.data.info
      }
    }
    return null
  },

  async refresh () {
    return axios({
      url: axios.defaults.baseURL + 'login/verify',
      method: 'post'
    }).then(success => {
      return Promise.resolve(success)
    }, error => {
      // if the network info is invalid, send to invalid page
      /* if (error.response.status === 401 && error.response.data.error.includes('Network credentials')) {
        router.push('/invalid-network')
      } else if (error.response.status === 500) {
        // internal db error likely
        router.push('/invalid-network')
      } */
      // because of how this is being used, the await is always
      // expecting a resolve return, so let's give them one
      Vue.prototype.$aimNotify.error(error.response)
      return Promise.resolve(null)
    })
  },

  isLoggedIn () {
    const userInfo = StorageService.get('user')
    return (userInfo && userInfo.apitoken)
  },

  isAdmin () {
    const userInfo = StorageService.get('user')
    return (userInfo && userInfo.isAdmin)
  },

  isBookkeeper () {
    const userInfo = StorageService.get('user')
    return (userInfo && userInfo.isBookkeeper)
  },

  async getCompanies () {
    const companies = []
    const userInfo = await this.getUserInfo()
    if (userInfo && userInfo.roles && userInfo.roles.length) {
      userInfo.roles.forEach((r) => {
        if (r.company_id) {
          companies.push({ id: r.company_id, name: r.company, organization_id: r.organization_id, isBookkeeper: false, calendar: r.calendar, contracttype_id: r.contracttype_id, unread: r.unread })
        } else if (r.bkor_company_id) {
          companies.push({ id: r.bkor_company_id, name: r.bkor_company, organization_id: r.bkor_organization_id, isBookkeeper: true, calendar: r.calendar, contracttype_id: r.contracttype_id, unread: r.unread })
        }
      })
    }
    return companies.sort((a, b) => ('' + a.name).localeCompare(b.name))
  },

  getOrganizations () {
    const organizations = []
    const userInfo = StorageService.get('user')
    if (userInfo && userInfo.roles && userInfo.roles.length) {
      userInfo.roles.forEach((r) => {
        if (r.organization_id) {
          organizations.push({ id: r.organization_id, name: r.organization })
        } else if (r.bkor_organization_id) {
          organizations.push({ id: r.bkor_organization_id, name: r.bkor_organization })
        }
      })
    }
    return organizations
  },

  checkAgreements () {
    return axios({
      url: axios.defaults.baseURL + 'account/checkAgreements',
      method: 'GET'
    })
  },
  checkPasswordReset () {
    return axios({
      url: axios.defaults.baseURL + 'account/checkPasswordReset',
      method: 'GET'
    })
  },

  saveNewPassword (data) {
    return axios({
      url: axios.defaults.baseURL + 'account/saveNewPassword',
      method: 'POST',
      data: JSON.stringify({ data: data })
    })
  },

  setAgreed (type) {
    return axios({
      url: axios.defaults.baseURL + 'account/setAgreed/' + type,
      method: 'POST'
    })
  },

  setHeaders () {
    const userInfo = StorageService.get('user')
    if (userInfo && userInfo.apitoken) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + userInfo.apitoken
    } else {
      // remove any possible authorization header if user is invalid
      delete axios.defaults.headers.common.Authorization
    }
  },

  hideUnreadMessagesAlert () {
    let userInfo = StorageService.get('user')
    userInfo.alertUnreadMessages = false
    StorageService.set('user', userInfo)
    userInfo = StorageService.get('user')
    console.dir(userInfo)
  }
}

export default UserService
