<template>
  <router-link
    v-if="messages"
    :to="linkto"
    :title="messages.total.toString() + ' unread'"
    class="px-2"
  >
    <span class="m-0">
      <span class="nav-link d-inline d-lg-none pr-1">Messages</span>
      <b-icon
        v-if="linkto === '/messaging'"
        :icon="(messages.total > 0) ? 'envelope-fill' : 'envelope'"
        :class="(messages.total > 0) ? 'text-danger' : ''"
      ></b-icon>
      <b-icon
        v-if="linkto === '/feedback'"
        :icon="(messages.total > 0) ? 'question-octagon-fill' : 'question-octagon'"
        :class="(messages.total > 0) ? 'text-danger' : ''"
      ></b-icon>
    </span>
    <b-badge
      v-if="messages.total"
      variant="danger"
      class="py-1"
      pill
    >{{ messages.total }}</b-badge>
  </router-link>
</template>
<script>
import MessageService from '@/services/MessageService'
import { bus } from '@/main'

export default {
  name: 'MessageNotifications',

  props: {
    linkto: {
      type: String,
      default: '/messaging'
    }
  },

  data () {
    return {
      messages: {
        total: 0,
        latest: []
      }
    }
  },

  async mounted () {
    this.messages = await MessageService.checkIn()
    bus.$on('messagesRefresh', () => {
      this.messages = MessageService.getMessages()
    })
    bus.$on('messageRead', (id) => {
      MessageService.checkIn()
    })
    bus.$on('login', () => {
      MessageService.checkIn()
    })
    bus.$on('logout', () => {
      MessageService.clearTimer()
    })
  }
}
</script>
