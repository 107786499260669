import axios from 'axios'
import StorageService from '@/services/StorageService'
import { bus } from '@/main'

const MessageService = {
  checkInMinutes: 10,
  checkInTimer: null,

  async initialize (data) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/initialize',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async send (data) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/send',
      method: 'POST',
      data: data // this is form data because of file uploads
    })
  },

  async list (data) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/list',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async unread (data) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/unread',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async markAsUnread (id) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/markunread',
      method: 'POST',
      data: JSON.stringify({ id: id })
    })
  },

  async get (id) {
    return axios({
      url: axios.defaults.baseURL + 'messaging/get',
      method: 'POST',
      data: JSON.stringify({ id: id })
    })
  },

  setTimer () {
    this.clearTimer()
    this.checkInTimer = setTimeout(() => {
      this.checkIn()
    }, this.checkInMinutes * 60 * 1000)
  },

  clearTimer () {
    if (this.checkInTimer) {
      clearTimeout(this.checkInTimer)
    }
  },

  async checkIn () {
    return this.unread().then((response) => {
      StorageService.set('messages', response.data.info)
      bus.$emit('messagesRefresh', new Date())
      this.setTimer()
      return this.getMessages()
    }).catch(() => {
      // probably not logged in
    })
  },

  getMessages () {
    return StorageService.get('messages')
  }
}

export default MessageService
