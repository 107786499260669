<template>
  <div class="main-app">
    <header>
      <Navigation type="client"></Navigation>
    </header>
    <main style="padding: 0 15px;">
      <b-alert
        v-if="info && info.alertUnreadMessages && info.unreadMessages > 0"
        show
        variant="danger"
        dismissible
        fade
        @dismissed="hideUnreadMessagesAlert"
      >
        You have {{ info.unreadMessages }} unread messages! <router-link to="/feedback">Review them now!</router-link>
      </b-alert>

      <content>
        <slot></slot>
      </content>

      <footer>
      </footer>
    </main>
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import Navigation from '@/components/Navigation'
import MessageService from '@/services/MessageService'
import { bus } from '@/main'

export default {
  name: 'Default',
  components: { Navigation },

  data () {
    return {
      info: null
    }
  },

  async mounted () {
    this.info = await UserService.getUserInfo()

    bus.$on('messagesRefresh', () => {
      const msg = MessageService.getMessages()
      this.info.unreadMessages = msg.total
    })
    bus.$on('messageRead', (id) => {
      MessageService.checkIn()
    })
  },

  methods: {
    hideUnreadMessagesAlert () {
      return UserService.hideUnreadMessagesAlert()
    }
  }
}
</script>
