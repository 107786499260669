<template>
  <div class="main-app">
    <header>
      <Navigation></Navigation>
    </header>
    <main style="padding: 0 15px;">
      <content>
        <slot></slot>
      </content>

      <footer>
      </footer>
    </main>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'Anonymous',
  components: { Navigation }
}
</script>
