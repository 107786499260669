<template>
  <b-navbar
    toggleable="lg"
    class="sbs-nav"
  >
    <b-navbar-brand>
      <a href="/"><img
          src="/img/logo/white_512.png"
          class="logo img-fluid"
        ></a>
    </b-navbar-brand>

    <b-navbar-toggle
      v-if="loggedIn"
      target="nav-collapse"
    >
      <template #default>
        <b-icon icon="list"></b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav
        v-if="type === 'client'"
        class="ml-auto"
      >
        <b-nav-item>
          <router-link to="/dashboard">Dashboard</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/summary">Scorecard</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/documents">Documents</router-link>
        </b-nav-item>
        <b-nav-item-dropdown
          :text="username"
          right
        >
          <b-dropdown-item href="/help">Help Center</b-dropdown-item>
          <b-dropdown-item href="/contact">Contact Us</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/account">Account Settings</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item>
          <MessageNotifications linkto="/feedback"></MessageNotifications>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav
        v-else-if="type === 'bookkeeper'"
        class="ml-auto"
      >
        <b-nav-item>
          <router-link to="/bookkeeping">Bookkeeping Queue</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/report">Reports</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/summary">Scorecard</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/documents">Documents</router-link>
        </b-nav-item>
        <b-nav-item-dropdown
          :text="username"
          right
        >
          <b-dropdown-item href="/dashboard">Dashboard</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/account">Settings</b-dropdown-item>
          <b-dropdown-item href="/help">Help Center</b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item>
          <MessageNotifications linkto="/messaging"></MessageNotifications>
          <MessageNotifications linkto="/feedback"></MessageNotifications>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav
        v-else-if="type === 'admin'"
        class="ml-auto"
      >
        <b-nav-item>
          <router-link to="/admin/onboarding">Onboarding</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/bookkeeping">Bookkeeper Queue</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/report">Reports</router-link>
        </b-nav-item>

        <b-nav-item-dropdown
          text="Administration"
          right
        >
          <b-dropdown-item href="/admin/users">Users</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/admin/company">Companies</b-dropdown-item>
          <b-dropdown-item href="/admin/industry">Industries</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/admin/employee/activity">Employee Activity</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/admin/templates">Templates</b-dropdown-item>
          <b-dropdown-item href="/admin/help">Help Admin</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          :text="username"
          right
        >
          <b-dropdown-item href="/dashboard">Dashboard</b-dropdown-item>
          <b-dropdown-item href="/summary">Scorecard</b-dropdown-item>
          <b-dropdown-item href="/documents">Documents</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="/account">Settings</b-dropdown-item>
          <b-dropdown-item href="/help">Help Center</b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item>
          <MessageNotifications linkto="/messaging"></MessageNotifications>
          <MessageNotifications linkto="/feedback"></MessageNotifications>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav
        v-else
        class="ml-auto"
      >
        <b-nav-item v-if="loggedIn">
          <b-link @click.prevent="logout">Log Out</b-link>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import UserService from '@/services/UserService'
import MessageNotifications from '@/components/messaging/Notifications'

export default {
  name: 'Navigation',
  components: { MessageNotifications },
  props: {
    type: {
      type: String,
      default: 'anonymous'
    }
  },

  data () {
    return {
      userinfo: null,
      username: null
    }
  },

  mounted () {
    this.setname()
  },

  methods: {
    logout () {
      UserService.logout()
    },

    async setname () {
      this.userinfo = await UserService.getUserInfo()

      if (this.userinfo && this.userinfo.apitoken) {
        this.username = this.userinfo.firstname + ' ' + this.userinfo.lastname
      }
    },

    hideUnreadMessagesAlert () {
      return UserService.hideUnreadMessagesAlert()
    }
  },

  computed: {
    loggedIn () {
      return UserService.isLoggedIn()
    }
  }
}
</script>
