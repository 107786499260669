<template>
  <div class="main-app">
    <header>
      <Navigation type="bookkeeper"></Navigation>
    </header>
    <main style="padding: 0 15px;">
      <content>
        <slot></slot>
      </content>
      <footer>
      </footer>
    </main>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'Bookkeeper',
  components: { Navigation }
}
</script>
