<template>
  <component
    id="app"
    :is="layout"
    class="container-fluid"
  >
    <notifications
      group="notices"
      :ignoreDuplicates="true"
      :max="1"
      role="status"
      :closeOnClick="true"
    ></notifications>

    <loader-overlay>
      <transition :name="transition">
        <router-view />
      </transition>
      <Agreements></Agreements>
      <PasswordReset></PasswordReset>
    </loader-overlay>
  </component>
</template>

<script>
import UserService from '@/services/UserService'
import MessageService from '@/services/MessageService'

import Agreements from '@/components/Agreements'
import PasswordReset from '@/components/PasswordReset'

import { bus } from '@/main'

export default {
  name: 'app',
  data () {
    return {
      transition: null
    }
  },

  components: {
    Agreements,
    PasswordReset
  },

  mounted () {
    if (UserService.isLoggedIn()) {
      MessageService.checkIn()
    }

    bus.$on('login', () => {
      MessageService.checkIn()
    })
  },

  computed: {
    layout () {
      return (this.$route.meta.layout || 'anonymous') + '-layout'
      // return 'default-layout'
    }
  },

  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  }
}
</script>

<style lang="scss">
@import "./app.scss";
</style>
