var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { staticClass: "sbs-nav", attrs: { toggleable: "lg" } },
    [
      _c("b-navbar-brand", [
        _c("a", { attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "logo img-fluid",
            attrs: { src: "/img/logo/white_512.png" }
          })
        ])
      ]),
      _vm.loggedIn
        ? _c("b-navbar-toggle", {
            attrs: { target: "nav-collapse" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [_c("b-icon", { attrs: { icon: "list" } })]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              697932066
            )
          })
        : _vm._e(),
      _c(
        "b-collapse",
        { attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _vm.type === "client"
            ? _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/dashboard" } }, [
                        _vm._v("Dashboard")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/summary" } }, [
                        _vm._v("Scorecard")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/documents" } }, [
                        _vm._v("Documents")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: _vm.username, right: "" } },
                    [
                      _c("b-dropdown-item", { attrs: { href: "/help" } }, [
                        _vm._v("Help Center")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "/contact" } }, [
                        _vm._v("Contact Us")
                      ]),
                      _c("b-dropdown-divider"),
                      _c("b-dropdown-item", { attrs: { href: "/account" } }, [
                        _vm._v("Account Settings")
                      ]),
                      _c("b-dropdown-divider"),
                      _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                        _vm._v("Logout")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("MessageNotifications", {
                        attrs: { linkto: "/feedback" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.type === "bookkeeper"
            ? _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/bookkeeping" } }, [
                        _vm._v("Bookkeeping Queue")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/report" } }, [
                        _vm._v("Reports")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/summary" } }, [
                        _vm._v("Scorecard")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/documents" } }, [
                        _vm._v("Documents")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: _vm.username, right: "" } },
                    [
                      _c("b-dropdown-item", { attrs: { href: "/dashboard" } }, [
                        _vm._v("Dashboard")
                      ]),
                      _c("b-dropdown-divider"),
                      _c("b-dropdown-item", { attrs: { href: "/account" } }, [
                        _vm._v("Settings")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "/help" } }, [
                        _vm._v("Help Center")
                      ]),
                      _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                        _vm._v("Logout")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("MessageNotifications", {
                        attrs: { linkto: "/messaging" }
                      }),
                      _c("MessageNotifications", {
                        attrs: { linkto: "/feedback" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.type === "admin"
            ? _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/onboarding" } },
                        [_vm._v("Onboarding")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/bookkeeping" } }, [
                        _vm._v("Bookkeeper Queue")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("router-link", { attrs: { to: "/report" } }, [
                        _vm._v("Reports")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: "Administration", right: "" } },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/users" } },
                        [_vm._v("Users")]
                      ),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/company" } },
                        [_vm._v("Companies")]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/industry" } },
                        [_vm._v("Industries")]
                      ),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/employee/activity" } },
                        [_vm._v("Employee Activity")]
                      ),
                      _c("b-dropdown-divider"),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/templates" } },
                        [_vm._v("Templates")]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { href: "/admin/help" } },
                        [_vm._v("Help Admin")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: _vm.username, right: "" } },
                    [
                      _c("b-dropdown-item", { attrs: { href: "/dashboard" } }, [
                        _vm._v("Dashboard")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "/summary" } }, [
                        _vm._v("Scorecard")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "/documents" } }, [
                        _vm._v("Documents")
                      ]),
                      _c("b-dropdown-divider"),
                      _c("b-dropdown-item", { attrs: { href: "/account" } }, [
                        _vm._v("Settings")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "/help" } }, [
                        _vm._v("Help Center")
                      ]),
                      _c("b-dropdown-item", { on: { click: _vm.logout } }, [
                        _vm._v("Logout")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    [
                      _c("MessageNotifications", {
                        attrs: { linkto: "/messaging" }
                      }),
                      _c("MessageNotifications", {
                        attrs: { linkto: "/feedback" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _vm.loggedIn
                    ? _c(
                        "b-nav-item",
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.logout.apply(null, arguments)
                                }
                              }
                            },
                            [_vm._v("Log Out")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }